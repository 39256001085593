import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./assets/scss/themes.scss";
import "./App.css";
import "react-phone-number-input/style.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import {
  AdminRoute,
  AdminReadOnlyRoute
} from "./components/common/admin-route.component";
import useAxios from "./api/useAxios";
import { useQuery } from "react-query";
import { useState, useEffect, lazy, Suspense } from "react";
import ScrollToTop from "./components/common/scroll-to-top.component";
import "react-quill/dist/quill.snow.css";
// Public View Imports

import { Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import TaxManagement from "./pages/admin-dash/tax-management-page";



// Admin Dashboard Imports
const AdminLayout = lazy(() => import("./layout/admin-dash"));
const AdminSignIn = lazy(() => import("./pages/admin-dash/signin-page"));
const AdminDashPage = lazy(() => import("./pages/admin-dash/dashboard-page"));
const MonthlyStatsPage = lazy(() => import("./pages/admin-dash/monthly-stats"))
const AdminActiveCompanies = lazy(() => import("./pages/admin-dash/companies/active-companies-page"));
const SignUpRequests = lazy(() => import("./pages/admin-dash/companies/signup-requests-page"));
const SuspendedCompanies = lazy(() => import("./pages/admin-dash/companies/suspended-companies-page"));
const AdminListOfSkills = lazy(() => import("./pages/admin-dash/skills/skills-list-page"));
const AddNewSkill = lazy(() => import("./pages/admin-dash/skills/add-new-skills-page"));
const ApproveSkills = lazy(() => import("./pages/admin-dash/skills/approve-skills-page"));
const CounsellingRequestsPage = lazy(() => import("./pages/admin-dash/counselling/counselling-requests-page"));
const CounsellorsPage = lazy(() => import("./pages/admin-dash/counselling/counsellors-page"));
const AddNewTrainingPage = lazy(() => import("./pages/admin-dash/trainings/add-new-training-page"));
const ListOfTrainingsPage = lazy(() => import("./pages/admin-dash/trainings/ViewTrainings/list-of-trainings-page"));
const TrainingPage = lazy(() => import("./pages/admin-dash/trainings/update-training-page"));
const TrainingsRequestsPage = lazy(() => import("./pages/admin-dash/trainings/trainings-requests-page"));
const TrainingAdminDetail = lazy(() => import("./pages/admin-dash/trainings/training-detail-page"));
const UpdateTrainingPage = lazy(() => import("./pages/admin-dash/trainings/update-training-page"));

const DemoRequests = lazy(() => import("./pages/admin-dash/demo-requests"));

const AdminShortlistedCandidateProfile = lazy(() => import("./pages/admin-dash/jobs/shortlisted-candidate-profile"));
const AdminShortlistedCandidates = lazy(() => import("./pages/admin-dash/jobs/shortlisted-candidates-page"));

const TrainersPage = lazy(() => import("./pages/admin-dash/trainers/trainers-page"));
const AddAdminSchedule = lazy(() => import("./pages/admin-dash/schedules/add-schedule-page"));
const ViewAdminEnrollments = lazy(() => import("./pages/admin-dash/schedules/view-enrollments-page"));
const ScheduleDetailAdmin = lazy(() => import("./pages/admin-dash/schedules/schedule-details-page"));
const TrainerDetailsPage = lazy(() => import("./pages/admin-dash/trainers/trainer-details-page"));
const TrainerTrainingsPage = lazy(() => import("./pages/admin-dash/trainers/ViewTrainings/trainer-trainings-page"));
const TrainerSchedulesPage = lazy(() => import("./pages/admin-dash/trainers/ViewSchedules/trainer-schedules-page"));

const ViewTrainingRequests = lazy(() => import("./pages/admin-dash/trainings/requests-list-page"));
const ViewCandidatesPage = lazy(() => import("./pages/admin-dash/candidates/ViewCandidates/list-of-candidates-page"));
const CandidateDetailsPage = lazy(() => import("./pages/admin-dash/candidates/candidate-details-page"));
const TraineeDetailPage = lazy(() => import("./pages/admin-dash/trainees/trainee-details-page"))
const CompanyJobsView = lazy(() => import("./pages/admin-dash/companies/viewJobs/view-jobs-page"));
const AdminJobDetail = lazy(() => import("./pages/admin-dash/jobs/job-detail-page"));

const { Spinner } = require("reactstrap/esm");

const CampusAmbassadorsAdminPage = lazy(() => import('./pages/admin-dash/campus-ambassadors/campus-ambassadors.page'));
const AmbassadorsReferralsAdminPage = lazy(() => import('./pages/admin-dash/campus-ambassadors/ambassador-referrals'));


const NotFoundPage = lazy(() => import("./pages/notFound.page"))

// public view pages
const PublicLayout = lazy(() => import("./layout/public-view"));
const SignInPage = lazy(() => import("./pages/public-view/authentication/sign-in.page"))

ReactGA.initialize("G-WVRK6VDMGM");


export const useStore = create(
  devtools(
    persist(
      (set) => ({
        userStatus: false,
        accessToken: null,
        refreshToken: null,
        userType: null,
        name: null,
        phone: null,
        email: null,
        profile_complete: null,
      }),
      {
        name: "auth-storage",
        getStorage: () => localStorage,
      }
    )
  )
);

function App() {
  let api = useAxios();

  const userType = useStore((state) => state.userType);
  const userStatus = useStore((state) => state.userStatus);

  const { data: unreadMessagesData, isSuccess: isUnreadMessagesSuccess } =
    useQuery(
      "unreadMessages",
      async () => {
        if (userType === "candidate" || userType === "company") {
          return api.get("chat/unread-messages");
        }
        return null;
      },
      {
        enabled: userType === "candidate" || userType === "company",
      }
    );

  const [unreadMessages, setUnreadMessages] = useState();

  useEffect(() => {
    if (unreadMessagesData?.data) {
      setUnreadMessages(unreadMessagesData?.data?.unread_chats);
    }
  }, [unreadMessagesData]);

  const userCategory = useStore((state) => state.userType);

  return (
    <Router>
      <div className="App">
        <ScrollToTop>
          <Suspense fallback={<div style={{ 'height': '100vh', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
            <Spinner type="grow" color="dark" />

          </div>}>
            <Routes>
              {/* =========== Public View Routes =========== */}

              <Route path="*" element={<NotFoundPage />} />

              <Route element={<PublicLayout />}>
                <Route path="/" element={<SignInPage />} />
                
            </Route>

              {/* =========== Admin Dashboard Routes =========== */}

              <Route element={<AdminLayout />}>
                

                <Route
                  path="/admin-read/dashboard"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <AdminDashPage />
                    </AdminReadOnlyRoute>
                  }
                />

                <Route
                  path="/admin-read/monthly-stats"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <MonthlyStatsPage />
                    </AdminReadOnlyRoute>
                  }
                />

                <Route
                  path="/admin-read/careers-detail-info/shortlisted-candidates/:jobID"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <AdminShortlistedCandidates />
                    </AdminReadOnlyRoute>
                  }
                />


                <Route
                  path="/admin-read/candidate-profile/:candidateID"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <AdminShortlistedCandidateProfile />
                    </AdminReadOnlyRoute>
                  }
                />

                <Route
                  path="/admin-read/active-companies"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <AdminActiveCompanies />
                    </AdminReadOnlyRoute>
                  }
                />


                <Route
                  path="/admin-read/suspended-companies"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <SuspendedCompanies />
                    </AdminReadOnlyRoute>
                  }
                />


                <Route
                  path="/admin-read/signup-requests"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <SignUpRequests />
                    </AdminReadOnlyRoute>
                  }
                />


                <Route
                  path="/admin-read/list-of-skills"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <AdminListOfSkills />
                    </AdminReadOnlyRoute>
                  }
                />

              
                <Route
                  path="/admin-read/approve-skills"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <ApproveSkills />
                    </AdminReadOnlyRoute>
                  }
                />

                <Route
                  path="/admin-read/counselling-requests"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <CounsellingRequestsPage />
                    </AdminReadOnlyRoute>
                  }
                />

                <Route
                  path="/admin-read/counsellors"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <CounsellorsPage />
                    </AdminReadOnlyRoute>
                  }
                />


                <Route
                  path="/admin-read/training-details/:trainingID"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <TrainingAdminDetail />
                    </AdminReadOnlyRoute>
                  }
                />


                <Route
                  path="/admin-read/trainee-details/:traineeSlug"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <TraineeDetailPage />
                    </AdminReadOnlyRoute>
                  }
                />

           
                <Route
                  path="/admin-read/schedule-details/:scheduleID"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <ScheduleDetailAdmin />
                    </AdminReadOnlyRoute>
                  }
                />


              
                <Route
                  path="/admin-read/schedule/enrollments/:scheduleID"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <ViewAdminEnrollments />
                    </AdminReadOnlyRoute>
                  }
                />

                <Route
                  path="/admin-read/training-requests/:trainingID"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <ViewTrainingRequests />
                    </AdminReadOnlyRoute>
                  }
                />


              
                <Route
                  path="/admin-read/trainers"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <TrainersPage />
                    </AdminReadOnlyRoute>
                  }
                />


           
                <Route
                  path="/admin-read/trainer-details/:trainerID"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <TrainerDetailsPage />
                    </AdminReadOnlyRoute>
                  }
                />


                <Route
                  path="/admin-read/trainer-trainings"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <TrainerTrainingsPage />
                    </AdminReadOnlyRoute>
                  }
                />

                <Route
                  path="/admin-read/trainer-schedules"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <TrainerSchedulesPage />
                    </AdminReadOnlyRoute>
                  }
                />


                <Route
                  path="/admin-read/list-of-candidates"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <ViewCandidatesPage />
                    </AdminReadOnlyRoute>
                  }
                />

                <Route
                  path="/admin-read/candidate-details/:candidateID"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <CandidateDetailsPage />
                    </AdminReadOnlyRoute>
                  }
                />

                <Route
                  path="/admin-read/company/:companyID"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <CompanyJobsView />
                    </AdminReadOnlyRoute>
                  }
                />

                <Route
                  path="/admin-read/campus-ambassadors"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <CampusAmbassadorsAdminPage />
                    </AdminReadOnlyRoute>
                  }
                />


                <Route
                  path="/admin-read/campus-ambassador-referrals"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <AmbassadorsReferralsAdminPage />
                    </AdminReadOnlyRoute>
                  }
                />


                <Route
                  path="/admin-read/demo-requests"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <DemoRequests />
                    </AdminReadOnlyRoute>
                  }
                />
         
                <Route
                  path="/admin-read/tax-management"
                  element={
                    <AdminReadOnlyRoute userType={userCategory}>
                      <TaxManagement />
                    </AdminReadOnlyRoute>
                  }
                />

              </Route>

              
            </Routes>
          </Suspense>
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;