import React, { useState } from 'react';
import { Col, Container, Row, Input, Button, Spinner } from 'reactstrap';
import BreadCrumb from '../../components/common/breadcrumb.component';
import { useMutation, useQuery } from 'react-query'
import useAxios from '../../api/useAxios';
import { ToastContainer } from 'react-toastify';
import showToast from '../../utils/toast-types';
import { taxManagementSchema } from '../../utils/form-schemas';
import { useFormik } from 'formik';


const TaxManagement = () => {

    document.title = "Tax Management  | Search O Pal"

    const { isLoading, isError, error, data, isSuccess, refetch } = useQuery(
        `tax-management`,
        async () => api.get('/trainings/fee-training'),
        { cacheTime: 0 }
    );

    const existingData = data?.data?.data ?? false

    let api = useAxios()


    return (
        <div className="page-content">

            <BreadCrumb title="Tax Management" />

            <Container className='mt-3 px-0 px-md-3 text-start'>
                {!isLoading ? 

                    <div className="row">
                        <div className="col-md-4 col-12">
                            <label className='mb-1' htmlFor="skill">GST*</label>
                            <div className='input-group'>
                            <input type="text"
                                class="form-control"
                                placeholder="Enter General Sales Tax Percentage"
                                aria-label="GST*"
                                className={"form-control form-control-icon"}
                                name='gst'
                                value={existingData.gst}
                                disabled
                            />

                            <span class="input-group-text" id="basic-addon2">%</span>
                            </div>
                                                   </div>



                        <div className="col-md-4 col-12">
                            <label className='mb-1' htmlFor="skill">Withholding Tax*</label>

                            <div className="input-group">
                                <input type="text"
                                    class="form-control"
                                    placeholder="Enter Withholding Tax Percentage"
                                    aria-label="Withholding Tax*"
                                    className="form-control form-control-icon"
                                    name='withholding_tax'
                                    value={existingData.withholding_tax}
                                    disabled
                                />

                                <span class="input-group-text" id="basic-addon2">%</span>
                            </div>   
                        </div>


                        <div className="col-md-4 col-12">

                            <label className='mb-1' htmlFor="skill">Platform Fee for training*</label>
                            <div className="input-group">

                                <input type="text"
                                    class="form-control"
                                    placeholder="Enter Platform Fee for Training"
                                    aria-label="Platform fee for training"
                                    aria-describedby="basic-addon2"
                                    className="form-control form-control-icon"
                                    name='platform_fee'
                                    value={existingData.platform_fee}
                                    disabled
                                />

                                <span class="input-group-text" id="basic-addon2">%</span>
                            </div>
                        </div>
                    </div>
            
                :
                <div className='text-center mt-5'>
                    <Spinner size="lg" className="flex-shrink-0 ms-2"></Spinner>
                </div>
                }

            </Container>

            <ToastContainer />

        </div>
    )
}


export default TaxManagement;
