import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const showToast = (responseMsg, toastType) => {
      if (toastType === "success"){
        toast.success(responseMsg)           
      }
      else if (toastType === "warning"){        
        toast.warning(responseMsg)
      }
      else if (toastType === "info"){        
        toast.info(responseMsg)   
      }
      else if (toastType === "error"){        
        toast.error(responseMsg)   
      }
      
      else{
        toast.info(responseMsg)   
      }
  }

export default showToast;