let IP;

if (process.env.NODE_ENV === "production") {
  IP = "searchopal-dev-11311db4105f.herokuapp.com";
} else {
  // IP = "192.168.100.48:8000";
  // IP = "192.168.100.42:8001";
  // IP = "192.168.100.90:8000"
  // IP = "192.168.100.90:8000";
  // IP = "localhost:8000"; 
  IP = "https://60fec8517aa3.ngrok.app"; // for readonly admin panel
}

export default IP;