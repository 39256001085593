import * as yup from "yup";

const questionSchema = yup.object({
  questionText: yup.string().required("Question is required"),
  options: yup.array().of(
    yup.object({
      text: yup.string().required("Option text is required"),
      isCorrect: yup.boolean(),
    })
  ),
});

export const signUpSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  name: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  // .matches(/^[0-9]+$/, "Invalid Phone Number"),
  country: yup.string().required("Required"),
  password: yup.string().min(8).required("Required"),
});

export const addResourceSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string().email("Please enter a valid email").required("Required"),
  phone: yup.number(),
  designation: yup.string().required("Required"),
  status: yup.string().required("Required"),
  rate: yup.number().nullable(),
  rateType: yup.string().nullable(),
  rateCurrency: yup.string().nullable(),
});

export const addTrainingSchema = yup.object().shape({
  category: yup.string().required("Required"),
  duration: yup.number().required("Required"),
  timezone: yup.string().required("Required"),
  onlineDF: yup.boolean().required("Required"),
  classroomBasedDF: yup.boolean().required("Required"),
  onsiteDF: yup.boolean().required("Required"),


  onlineDF1: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF3: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF6: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF10: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF11: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),


  classroomBasedDF1: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF3: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF6: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF10: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF11: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  address: yup
    .string().when("classroomBasedDF", {
      is: true, then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

  city: yup
    .string().when("classroomBasedDF", {
      is: true, then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

  country: yup
    .string().when("classroomBasedDF", {
      is: true, then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

  onsiteDF1: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF3: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF6: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF10: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF11: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),



  description: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    })
    .min(300, "Description must be at least 300 characters"),
  who_should_attend: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  what_you_will_learn: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  course_outline: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  prerequisites: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  related_certification: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
});


export const editTrainingSchema = yup.object().shape({
  duration: yup.number().required("Required").positive("Duration must be positive"),
  timezone: yup.string().required("Required"),
  onlineDF: yup.boolean().required("Required"),
  classroomBasedDF: yup.boolean().required("Required"),
  onsiteDF: yup.boolean().required("Required"),


  onlineDF1: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF3: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF6: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF10: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF11: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),


  classroomBasedDF1: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF3: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF6: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF10: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF11: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  address: yup
    .string().when("classroomBasedDF", {
      is: true, then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

  city: yup
    .string().when("classroomBasedDF", {
      is: true, then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

  country: yup
    .string().when("classroomBasedDF", {
      is: true, then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

  onsiteDF1: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF3: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF6: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF10: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF11: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  description: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    })
    .min(300, "Description must be at least 300 characters"),
  who_should_attend: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  what_you_will_learn: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  course_outline: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  prerequisites: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  related_certification: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
});

export const adminEditTrainingSchema = yup.object().shape({
  duration: yup.number().required("Required").positive("Duration must be positive"),
  timezone: yup.string().required("Required"),
  onlineDF: yup.boolean().required("Required"),
  classroomBasedDF: yup.boolean().required("Required"),
  onsiteDF: yup.boolean().required("Required"),


  onlineDF1: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF3: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF6: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF10: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onlineDF11: yup
    .number().positive("Enter Valid Price").when("onlineDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),


  classroomBasedDF1: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF3: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF6: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF10: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  classroomBasedDF11: yup
    .number().positive("Enter Valid Price").when("classroomBasedDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  address: yup
    .string().when("classroomBasedDF", {
      is: true, then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

  city: yup
    .string().when("classroomBasedDF", {
      is: true, then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

  country: yup
    .string().when("classroomBasedDF", {
      is: true, then: yup.string().required("Required"),
      otherwise: yup.string().notRequired(),
    }),

  onsiteDF1: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF3: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF6: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF10: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  onsiteDF11: yup
    .number().positive("Enter Valid Price").when("onsiteDF", {
      is: true, then: yup.number().required("Required").positive("Enter Valid Price"),
      otherwise: yup.number().notRequired(),
    }),

  description: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    })
    .min(300, "Description must be at least 300 characters"),
  who_should_attend: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  what_you_will_learn: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  course_outline: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  prerequisites: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  related_certification: yup
    .string()
    .test("is-not-empty-or-blank", "Required", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
});

export const addScheduleSchema = yup.object().shape({
  training: yup.string().required("Required"),
  language: yup.mixed().required("Required"),
  start_date: yup.date().required("Required"),
  timezone: yup.string().required("Required"),
  end_date: yup
    .date()
    .required("Required")
    .min(yup.ref("start_date"), "End date must be after start date"),
  start_time: yup.string().required("Required"),
  end_time: yup
    .string()
    .required("Required")
    .test(
      "is-greater",
      "End Time must be after start time and within 8 hours",
      function (value) {
        const { start_time } = this.parent;
        if (!start_time || !value) {
          // If start_time or end_time is not provided, let other validations handle it
          return true;
        }

        const startTimeObj = new Date(`1970-01-01 ${start_time}`);
        const endTimeObj = new Date(`1970-01-01 ${value}`);
        const hourDifference = (endTimeObj - startTimeObj) / (1000 * 60 * 60);

        return hourDifference > 0 && hourDifference <= 8;
      }
    ),

});


export const editScheduleSchema = yup.object().shape({
  language: yup.string().required("Required"),
  timezone: yup.string().required("Required"),
  start_date: yup.date().required("Required"),
  end_date: yup
    .date()
    .required("Required")
    .min(yup.ref("start_date"), "End date must be greater then start date"),
  start_time: yup.string().required("Required"),

  end_time: yup
    .string()
    .required("Required")
    .test(
      "is-greater",
      "End Time must be after start time and within 8 hours",
      function (value) {
        const { start_time } = this.parent;
        if (!start_time || !value) {
          // If start_time or end_time is not provided, let other validations handle it
          return true;
        }

        const startTimeObj = new Date(`1970-01-01 ${start_time}`);
        const endTimeObj = new Date(`1970-01-01 ${value}`);
        const hourDifference = (endTimeObj - startTimeObj) / (1000 * 60 * 60);

        return hourDifference > 0 && hourDifference <= 8;
      }
    ),
});

export const companyProfileSchema = yup.object().shape({
  companyName: yup.string().required("Please enter company's name"),
  phone: yup.string().required("Please enter phone number"),
  city: yup.string(),
  country: yup.string().required("Please enter country"),
  website: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct URL!"
    ),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),
  newPassword: yup
    .string()
    .min(8, "New password must be at least 8 characters")
    .required("New password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export const resourceProfileSchema = yup.object().shape({
  name: yup.string().required("Please enter resources's name"),
  designation: yup
    .string()
    .required(
      "Please enter resource's designation e.g. Developer, QA Engineer, etc."
    ),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter email"),
  phone: yup.number("Please enter valid number"),
  rate: yup.number().nullable(),
  rateType: yup.string().nullable(),
  rateCurrency: yup.string().nullable(),
});

export const profileDescription = yup.object().shape({
  description: yup.string(),
});

export const AddSkillFormSchema = yup.object().shape({
  experience: yup.string().required("Required"),
  last_used: yup.string().required("Required"),
});

export const requestTrainingFormSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string().email("Please add valid email").required("Required"),
  phone: yup.number().typeError("Phone must be a number").required("Required"),
  password: yup.string().min(8).required("Required"),
  country: yup.string().required("Required"),
  delivery_format: yup.mixed().required("Required"),
  no_of_trainees: yup.mixed().required("Required"),


  df_country: yup
    .mixed()
    .when('delivery_format', {
      is: value => value === 'Classroom Based' || value === 'Onsite',
      then: yup.mixed().required('Required'),
      otherwise: yup.mixed(),
    }),


  city: yup
    .mixed()
    .when('delivery_format', {
      is: value => value === 'Classroom Based' || value === 'Onsite',
      then: yup.mixed().required('Required'),
      otherwise: yup.mixed().notRequired(),
    }),
  address: yup
    .string()
    .when('delivery_format', {
      is: value => value === 'Classroom Based' || value === 'Onsite',
      then: yup.string().required('Required'),
      otherwise: yup.string().notRequired(),
    }),

});

export const userRequestTrainingFormSchema = yup.object().shape({
  delivery_format: yup.mixed().required("Required"),
  no_of_trainees: yup.mixed().required("Required"),
  city: yup.mixed(),
  df_country: yup.mixed(),
  address: yup.mixed(),
});


export const enrolScheduleSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string().email("Please add valid email").required("Required"),
  phone: yup.number().typeError("Phone must be a number").required("Required"),
  password: yup.string().min(8).required("Required"),
  country: yup.string().required("Required"),
  no_of_trainees: yup.mixed().required("Required"),
})

export const userEnrolScheduleSchema = yup.object().shape({
  no_of_trainees: yup.mixed().required("Required"),
})


export const traineeProfileSchema = yup.object().shape({
  name: yup.string().required("Please enter name"),
  phone: yup.string().required("Please enter phone"),
  city: yup.string().required("Please enter city"),
  country: yup.string().required("Please enter country"),
});

export const candidateProfileSchema = yup.object().shape({
  name: yup.string().required("Please enter name"),
  phone: yup.string().required("Please enter phone"),
  city: yup.string().required("Please enter city"),
  designation: yup.string(),
  country: yup.string().required("Please enter country"),
  website: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct URL!"
    ),
  github_profile: yup
    .string()
    .matches(/^https:\/\/github\.com\/[A-Za-z0-9_-]+$/, "Invalid GitHub link"),
  gender: yup.string().required('Required')
});

export const candidateWorkExperienceSchema = yup.object().shape({
  company: yup.string().required("Please enter company name"),
  role: yup.string().required("Please enter  role"),
  city: yup.string().required("Please enter city"),
  country: yup.string().required("Please enter country"),

  start_date: yup.date().required("Please enter start date"),

  status: yup.boolean(),
  end_date: yup.date().when("status", {
    is: false,
    then: yup
      .date("Enter a valid date")
      .required("Please enter end date")
      .min(yup.ref("start_date"), "End date must be after start date"),
    otherwise: yup.date().nullable(),
  }),
});

export const trainingExperienceSchema = yup.object().shape({
  training_name: yup.string().required("Please enter training name"),
  language: yup.string().required("Please select language"),
  taught_courses: yup.string().required("Please select taught courses"),
  no_of_times: yup.string().required("Please enter No of times taught"),
  last_used: yup.date().required("Please enter start date"),
});

export const editTrainingExperienceSchema = yup.object().shape({
  training_name: yup.string().required("Please enter training name"),
  language: yup.mixed().required("Please select language"),
  taught_courses: yup.string().required("Please select taught courses"),
  no_of_times: yup.mixed().required("Please enter No of times taught"),
  last_used: yup.date().required("Please enter start date"),
});

export const addCertificationSchema = yup.object().shape({
  name: yup.string().required("Please enter Certification name"),
  organization: yup.string().required("Please enter Organization"),
  issue_date: yup.date().required("Please enter issue date"),
  status: yup.boolean(),
  expiry_date: yup.date().when("status", {
    is: false,
    then: yup
      .date()
      .required("Please enter expiry date")
      .min(yup.ref("issue_date"), "Expiry date must be after issue date"),
    otherwise: yup.date().nullable(),
  }),
});
export const addCandidateTrainingSchema = yup.object().shape({
  name: yup.string().required("Please enter Certification name"),
  organization: yup.string().required("Please enter organization or awarding body name"),
  description: yup.string().required("Please enter description"),
  status: yup.boolean(),
  start_date: yup.date().required('Please select start date'),
  end_date: yup.date().when("status", {
    is: false,
    then: yup
      .date()
      .required("Please enter expiry date")
      .min(yup.ref("start_date"), "End date must be after start date"),
    otherwise: yup.date().nullable(),
  }),
});

export const candidateEducationSchema = yup.object().shape({
  institute: yup.string().required("Please enter institute name"),
  category: yup.string().required("Please choose category").nullable(),
  degree: yup.string().required("Please enter your degree"),
  city: yup.string().required("Please enter city"),
  country: yup.string().required("Please enter country"),

  status: yup.boolean(),
  start_date: yup.date().required("Please enter start date"),

  end_date: yup.date().when("status", {
    is: false,
    then: yup
      .date()
      .required("Please enter end date")
      .min(yup.ref("start_date"), "End date must be after start date"),
    otherwise: yup.date().nullable(),
  }),
  description: yup.string(),
});

export const candidateDescriptionSchema = yup.object().shape({
  description: yup
    .string()
    .required("Please enter description")
    .test("is-not-empty-or-blank", "Please enter description", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
});


export const setJobInterviewSchema = yup.object().shape({
  interview_date: yup.date().required("Please select date"),
  // end_time: yup.time().required("Please select time"),
  mode: yup.mixed().required("Please select mode"),
  start_time: yup.string().required("Please select start time"),

  end_time: yup
    .string()
    .required("Please select end time")
    .test(
      "is-greater",
      "End Time must be after start time and within 8 hours",
      function (value) {
        const { start_time } = this.parent;
        if (!start_time || !value) {
          // If start_time or end_time is not provided, let other validations handle it
          return true;
        }

        const startTimeObj = new Date(`1970-01-01 ${start_time}`);
        const endTimeObj = new Date(`1970-01-01 ${value}`);
        const hourDifference = (endTimeObj - startTimeObj) / (1000 * 60 * 60);

        return hourDifference > 0 && hourDifference <= 8;
      }
    ),
  location: yup.string().when("mode", {
    is: "On Site",
    then: yup.string().required("Please enter location"),
    otherwise: yup.string().nullable(),
  }),
  platform: yup.string().when("mode", {
    is: "Remote",
    then: yup.string().required("Please enter platform"),
    otherwise: yup.string().nullable(),
  }),
  link: yup.string().when("mode", {
    is: "Remote",
    then: yup.string().required("Please enter meeting link"),
    otherwise: yup.string().nullable(),
  }),
  message: yup.string().required("Please enter message"),
});

export const candidateLanguageSchema = yup.object().shape({
  proficiency: yup.string().required("Please select proficiency level"),
});

export const editSkillSchema = yup.object().shape({
  name: yup.string().required("Skill name is required"),
});

export const remarksInterviewSchema = yup.object().shape({
  rating: yup.string().required("Rating is required"),
  remarks: yup.string().required("Remarks is required"),
});
export const remarksSchema = yup.object().shape({
  
  remarks: yup.string().required("Remarks is required"),
});

export const AddJobSchema = yup.object().shape({
  title: yup.string().required("Please enter title"),
  supporting_doc: yup.boolean(),
  location: yup.string().required("Please select location"),
  address: yup
    .string()
    .nullable()
    .when("location", {
      is: (location) =>
        location == "On Site" ||
        location == "Hybrid" ||
        location == "Office Based",
      then: yup.string().required("Please enter address").nullable()
    }),
  positions: yup.string().required("Please select positions"),
  degree: yup.string().required("Please select degree level").nullable(),
  jobType: yup.string().required("Please select Job Type"),
  salaryType: yup.string().required("Please select salary type").oneOf([
    'Unpaid',
    'Market Competitive',
    'Range',
    'Exact Amount'
  ], 'Invalid salary type').nullable(),
  currency: yup
    .string()
    .nullable()
    .when("salaryType", {
      is: (salaryType) =>
        salaryType !== "Market Competitive" &&
        salaryType &&
        salaryType !== "Unpaid",
      then: yup.string().required("Please select currency").nullable(),
    }),
  gender: yup.string().required("Required"),
  payment: yup
    .string()
    .nullable(true)
    .when("salaryType", {
      is: (salaryType) =>
        salaryType !== "Market Competitive" &&
        salaryType &&
        salaryType !== "Unpaid",
      then: yup
        .string()
        .required("Payment is required")
        .oneOf(
          ["Monthly", "Annually"],
          "Payment must be either Monthly or Annually"
        ),
    }),
    degrees: yup.array()
  .of(yup.number().required("Each degree must be a number"))
  .test('degrees-or-new-degrees', 'At least one degree must be selected', function(value) {
    const { new_degrees } = this.parent;
    return (value && value.length > 0) || (new_degrees && new_degrees.length > 0);
  }),

new_degrees: yup.array()
  .of(yup.string().required("Each degree must be a string"))
  .test('degrees-or-new-degrees', 'At least one degree must be selected', function(value) {
    const { degrees } = this.parent;
    return (value && value.length > 0) || (degrees && degrees.length > 0);
  }),
  responsibilities: yup.string().nullable(),
  requirements: yup.string().nullable(),
  benefits: yup.string().nullable(),
  description: yup
    .string()
    .required("Please enter description")
    .test("is-not-empty-or-blank", "Please enter description", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),
  contractPeriod: yup.string().when("jobType", {
    is: (jobType) =>
      jobType === "Temporary" ||
      jobType === "Contract" ||
      jobType === "Internship",
    then: yup.string().required("Please select contract period").nullable(),
    otherwise: yup.string().nullable(),
  }),

  experience: yup
    .string().required("Experience is required")
    .matches(/^\d+(-\d+)?$/, "Invalid number range format")
    .test(
      "range",
      "Either enter one year or range of input such as 1-2, 3-4, etc",
      (value) => {
        if (!value) {
          return false; // Optional field, so empty values are valid
        }

        const [start, end] = value.split("-").map(Number);
        if (isNaN(start)) {
          return false; // Invalid start number
        }

        if (isNaN(end)) {
          return true; // Only a single integer provided, so it's valid
        }

        return end > start; // End number must be greater than start number
      }
    ),

  hoursPerWeek: yup.string().when("jobType", {
    is: (jobType) => jobType === "Part-Time",
    then: yup
      .string()
      .matches(/^\d+(-\d+)?$/, "Invalid number range format")
      .test("range", "Either enter one value or range of values", (value) => {
        if (!value) {
          return false; // Optional field, so empty values are valid
        }

        const [start, end] = value.split("-").map(Number);
        if (isNaN(start)) {
          return false; // Invalid start number
        }

        if (isNaN(end)) {
          return true; // Only a single integer provided, so it's valid
        }

        return end > start; // End number must be greater than start number
      }),
    otherwise: yup.string().nullable(),
  }),

  contractLength: yup.string().when("jobType", {
    is: (jobType) =>
      jobType === "Temporary" ||
      jobType === "Contract" ||
      jobType === "Internship",
    then: yup
      .string()
      .matches(/^\d+(-\d+)?$/, "Invalid number range format")
      .test("range", "Either enter one value or range of values", (value) => {
        if (!value) {
          return false; // Optional field, so empty values are valid
        }

        const [start, end] = value.split("-").map(Number);
        if (isNaN(start)) {
          return false; // Invalid start number
        }

        if (isNaN(end)) {
          return true; // Only a single integer provided, so it's valid
        }

        return end > start; // End number must be greater than start number
      })
      .nullable(),
    otherwise: yup.string().nullable(),
  }),

  minimum: yup.number().when("salaryType", {
    is: (value) => value === "Range",
    then: yup
      .number()
      .required("Please enter minimum value")
      .test(
        "minimum",
        "Minimum value should be greater than or equal to zero",
        function (value) {
          return value >= 0;
        }
      ),
  }),

  maximum: yup.number().when("salaryType", {
    is: "Range",
    then: yup
      .number()
      .required("Please enter maximum value")
      .test(
        "maximum",
        "Maximum value should be greater than minimum value and greater than and can't be equal to zero",
        function (value) {
          const { minimum } = this.parent;
          if ((minimum && value && minimum >= value) || value < 0) {
            return false;
          }
          return true;
        }
      ),
    otherwise: yup.number().nullable(),
  }),

  amount: yup.string().when("salaryType", {
    is: (value) =>
      value === "Starting Amount" ||
      value === "Maximum Amount" ||
      value === "Exact Amount",
    then: yup
      .string()
      .matches(/^\d+(-\d+)?$/, "Invalid number range format")
      .test("range", "Either enter one value or range of values", (value) => {
        if (!value) {
          return false; // Optional field, so empty values are valid
        }

        const [start, end] = value.split("-").map(Number);
        if (isNaN(start)) {
          return false; // Invalid start number
        }

        if (isNaN(end)) {
          return true; // Only a single integer provided, so it's valid
        }

        return end > start; // End number must be greater than start number
      }),
    otherwise: yup.string(),
  }),
  // screening: yup.array().of(questionSchema),
  screening: yup
    .array()
    .of(questionSchema)
    .test(
      "is-valid-screening", // Name of the test
      "Screening array must either be empty or contain valid questions", // Error message
      (screening) =>
        screening.length === 0 ||
        screening.every((question) => questionSchema.isValidSync(question))
    ),
});

export const AddProjectSchema = yup.object().shape({
  title: yup.string()
    .required('Project title is required')
    .min(3, 'Title must be at least 3 characters long')
    .max(100, 'Title cannot exceed 100 characters'),

  description: yup
    .string()
    .required("Please enter description")
    .test("is-not-empty-or-blank", "Please enter description", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),

  skills: yup.array()
    .of(yup.number())
    .required('Skills are required')
    .test(
      "is-not-empty",
      "At least one skill is required",
      (value) => value && value.length > 0
    ),

  responsibilities: yup
    .string()
    .required("Please enter responsibilities")
    .test("is-not-empty-or-blank", "Please enter responsibilities", (value) => {
      return (
        value !== undefined &&
        value.trim() !== "" &&
        value.trim() !== "<p><br></p>"
      );
    }),

  work_experience: yup.number().nullable(),
});

export const ContactFormSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Name must be at least 3 characters")
    .max(30, "Name can have at most 30 characters")
    .required("Name is required"),
  representation: yup
    .string()
    .oneOf(
      [
        "job-seeker",
        "employer",
        "trainer",
        "trainee",
        "career-counsellor",
        "career-counselling-seeker",
      ],
      "Invalid representation"
    )
    .required("This is required"),

  companyName: yup.string().when("representation", {
    is: "employer",
    then: yup.string().required("Company Name is required"),
  }),

  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be a valid number")
    .test("is-number", "Phone number must be a valid number", (value) => {
      if (!value) return true; // Allow empty values
      return /^\d+$/.test(value);
    }),

  message: yup.string().required("Message is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
});

export const ResourceOutsourceSchema = yup.object().shape({
  startDate: yup.date(),
  endDate: yup.date(),
  engagementHours: yup.number().nullable(),
  proposedRate: yup.number().nullable(),
  message: yup.string().nullable(),
});

export const TrainingsSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be a valid number")
    .test("is-number", "Phone number must be a valid number", (value) => {
      if (!value) return true; // Allow empty values
      return /^\d+$/.test(value);
    }),
  representation: yup.string().required("Representation is required"),
  desired_training: yup.string().required("Desired training is required"),
  message: yup.string(),
  // other_training: yup.string().when("desired_training", {
  //   is: "other",
  //   then: yup.string().required("Other training is required"),
  // }),
});


export const becomeTrainerSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be a valid number")
    .test("is-number", "Phone number must be a valid number", (value) => {
      if (!value) return true; // Allow empty values
      return /^\d+$/.test(value);
    }),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
});


export const becomeTrainerWithcustomTrainingSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be a valid number")
    .test("is-number", "Phone number must be a valid number", (value) => {
      if (!value) return true; // Allow empty values
      return /^\d+$/.test(value);
    }),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  trainingName: yup.string().required("Trainer Name is required"),
  trainingBody: yup.string().required("Training Body is required"),
  experience: yup.string().required("Years of Experience is required"),
});

export const CounsellingSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be a valid number")
    .test("is-number", "Phone number must be a valid number", (value) => {
      if (!value) return true;
      return /^\d+$/.test(value);
    }),
  representation: yup.string().required("Representation is required"),
  category: yup.string().required("Category is required"),
  message: yup.string().required("Message is required"),
});


export const becomeCounsellorSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be a valid number")
    .test("is-number", "Phone number must be a valid number", (value) => {
      if (!value) return true;
      return /^\d+$/.test(value);
    }),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  topics: yup.string().required("Counselling Experties are required"),
});

export const CompleteSignInSchema = yup.object({
  countryName: yup.object().nullable().required("Country is required"),
  category: yup.string().required("You must select a category"),
  name: yup.string().when("category", {
    is: (value) => value === "job-seeker" || value === "",
    then: yup.string().required("Name is required"),
    otherwise: yup.string(),
  }),
  companyName: yup.string().when("category", {
    is: "employer",
    then: yup.string().required("Company Name is required"),
    otherwise: yup.string(),
  }),
});

export const CompleteSignupSchemaEmployer = yup.object({
  countryName: yup.object().nullable().required("Country is required"),
  category: yup.string().required("You must select a category"),
  phone: yup.string().required("Phone is Required"),
});

export const CompleteSignupSchemaJobSeeker = yup.object({
  phone: yup.string().required("Phone is Required"),
  countryName: yup.object().nullable().required("Country is required"),
});

export const forgotPasswordSchema = yup.object({
  email: yup.string().email("Invalid email").required("Email is required"),
});

export const resetPasswordSchema = yup.object({
  password: yup.string().min(8).required("Required"),
});

export const becomeCampusAmbassadorSchema = yup.object({

  university: yup.string().required("Required"),
  category: yup.string().required("Required"),
  degree: yup.string().required("Required"),
  semester: yup.string().required("Required"),
  startingYear: yup.string().required("Required"),
  endingYear: yup.string().required("Required"),
  residenceStatus: yup.string().required("Required"),
  marketingExperience: yup.string().required("Required"),
  WhyDoYouWant: yup.string().required("Required"),
  HowDidYouHear: yup.string().required("Required"),

  city: yup.string().required("Required"),
  country: yup.string().required("Required"),
})








export const DemoFormSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Name must be at least 3 characters")
    .max(30, "Name can have at most 30 characters")
    .required("Name is required"),
  category: yup
    .string()
    .oneOf(
      [
        "Job Seeker",
        "Employer",
        "Trainer",
        "Counselor",
        "Campus Ambassador",
      ],
      "Invalid representation"
    )
    .required("This is required"),

  desired_services: yup
    .array()
    .min(1, "At least one service must be selected")
    .required("Required"),  

  phone: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d+$/, "Phone number must be a valid number")
    .test("is-number", "Phone number must be a valid number", (value) => {
      if (!value) return true; // Allow empty values
      return /^\d+$/.test(value);
    }),

  message: yup.string().required("Message is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  company: yup.string().when('category', {
    is: 'Employer',
    then: yup.string().required('Company is required'),
    otherwise: yup.string(),
  }),
});