import IP from "./variable";

const PROTOCOL = window.location.protocol;
// let BASE_URL_WITH_PROTOCOL = `${PROTOCOL}//${IP}`;
let BASE_URL_WITH_PROTOCOL = `${IP}`;

if (process.env.NODE_ENV === "production") {
    BASE_URL_WITH_PROTOCOL = `${PROTOCOL}//${IP}`;
}


export default BASE_URL_WITH_PROTOCOL;