import React from "react";
import { Container } from "reactstrap";

const BreadCrumb = ({ title, pageTitle }) => {
  return (
    <>
      <Container fluid className="px-0 page-title-box">
        <div className="container text-start">
          <h3 className="mb-sm-0">{title}</h3>
        </div>
      </Container>
    </>
  );
};

export default BreadCrumb;
